import styled from "styled-components";

const Logo = styled.img`
  @media only screen and (max-width: 575px) {
    //flex-direction: column;
    margin: 10px 0 0 0;
  }
  @media only screen and (min-width: 576px) {
    margin: 10px 30px 0px 0px;
  }
  @media only screen and (min-width: 768px) {
    margin: 10px 30px 0px 0px;
  }
  @media only screen and (min-width: 992px) {
    margin: 10px 80px 0px 0px;
  }
  @media only screen and (min-width: 1200px) {
    margin: 10px 150px 0px 0px;
  }
  @media only screen and (min-width: 1400px) {
    margin: 10px 200px 0px 0px;
  }
  height: 56px;
  width: 142px;
  display: inline-block;
  vertical-align: middle;
  //${(props) => props.align ? `text-align: ${props.align};` : "left;"}
`;

export default Logo;